import React from 'react';

function Skills() {
    return (
        <div className="about">
            <h3>Skills</h3>
            <p>Angular, React, NodeJS, Java, TypeScript</p>
        </div>
    );
}

export default Skills;
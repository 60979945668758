import React from 'react';

function Interests() {
    return (
        <div className="about">
            <h3>Interests</h3>
            <p>Travel. Public transport networks. News. Outing with friends. Parisian life.</p>
{/*
            <p>Food expert. Music scholar. Reader. Internet fanatic. Bacon buff. Entrepreneur. Travel geek. Pop culture ninja. Coffee fanatic.</p>
*/}
        </div>
    );
}

export default Interests;